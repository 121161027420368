import SA from 'superagent';
import { addCommas } from './addCommas';

/*
{
  "tx_closed_30_days": 21552,
  "closed_transactions": 3687618,
  "users": 108978,
  "transactions": 5249785
}
*/

const formatClosedTx = closedTx => {
  return `${closedTx.toString().slice(0, 1)}.${closedTx.toString().slice(1, 3)}`;
}

const DEV_URL = 'https://dev.paperlesspipeline.com/stats/';
const PROD_URL = 'https://app.paperlesspipeline.com/stats/';

// const IS_DEV =  process?.env?.NODE_ENV === 'development';
// console.log('IS_DEV: ' + IS_DEV);

const Stats = {
  timestamp: Date.now() || '12345',
  async getStats(setValues, txMillions) {
    // const url = process.env.NODE_ENV === 'development' ? DEV_URL : PROD_URL;
    try {
      const response = await SA.get(PROD_URL)
        .query({ now: this.timestamp });
      if (
        response.body &&
        response.body.users &&
        response.body.users.toString().length &&
        response.body.closed_transactions &&
        response.body.closed_transactions.toString().length
      ) {
        const users = addCommas(response.body.users.toString());
        if (txMillions) {
          return setValues({
            users: users,
            closed_tx_millions: formatClosedTx(response.body.closed_transactions.toString()), // this will format into millions, "2.42 million",
            transactions: response.body.transactions,
            tx_closed_30_days: response.body.tx_closed_30_days,
            company_sign_ups: response.body.signups,
            closed_transactions: response.body.closed_transactions
          });
        }
        return setValues({
          users: users,
          closed_tx: addCommas(response.body.closed_transactions.toString()),
          closed_transactions: response.body.closed_transactions
        });
      }
      return new Error('get stats error');
    } catch (err) {
      return new Error(err);
    }
  },
};

export default Stats;